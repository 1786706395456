@font-face {
  font-family: 'SukhumvitSet-Bold';
  src: url(assets/fonts/SukhumvitSet-Bold.ttf) format("opentype");
}

@font-face {
  font-family: 'SukhumvitSet-SemiBold';
  src: url(assets/fonts/SukhumvitSet-SemiBold.ttf) format("opentype");
}

@font-face {
  font-family: 'SukhumvitSet-Medium';
  src: url(assets/fonts/SukhumvitSet-Medium.ttf) format("opentype");
}

@font-face {
  font-family: 'SukhumvitSet-Thin';
  src: url(assets/fonts/SukhumvitSet-Thin.ttf) format("opentype");
}

@font-face {
  font-family: 'SukhumvitSet-Light';
  src: url(assets/fonts/SukhumvitSet-Light.ttf) format("opentype");
}

body {
  width: 100%;
}

body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-thumb {
  background: rgba(204,204,204,0.5); 
}

body::-webkit-scrollbar-thumb:hover {
  background: #999999; 
}

#root {
  height: 100%;
  width: 100%;
  background: #e6e6e6;
  
}

.login-bg {
  background:linear-gradient(to top, #cccccc 0%, #f2f2f2 100%);
  height:100vh;
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-container {
  width: 450px;
  border-radius: 20px;
  border: 4px solid #ffffff;
  background:linear-gradient(to top, rgba(204,204,204, 0.1) 0%, #ffffff 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-form {
  width: 85%;
}

.login-logo {
  height: 160px;
  width: auto;
}

.login-input-container {
  background-color: #ffffff;
  width: 100%;
  border-radius: 6px;
  padding: 8px 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 45px;
  font-family: "SukhumvitSet-Medium";
}

.login-input-icon-container {
  width: 11%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #b3b3b3;
  height: 100%;
}

.login-icon {
  height: 18px;
  width: auto;
}

.login-input-box {
  width: 89%;
}

.login-input {
  margin-left: 10px;
  width: 95%;
  border:none;
  padding-left: 10px;
  color: #666666;
  font-family: "SukhumvitSet-Medium";
}

.login-input:focus {
  outline: none;
}

.login-other-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-account {
  font-family: "SukhumvitSet-SemiBold";
  color: #b31117;
  font-size: 16px;
  margin-bottom: 0;
  cursor: pointer;
}

.middle-bar {
  border-right: 1px solid #b3b3b3;
  height: 25px;
}

.forget-password {
  font-family: "SukhumvitSet-SemiBold";
  color: #999999;
  font-size: 16px;
  margin-bottom: 0;
  cursor: pointer;
}

.login-btn {
  background-color: #b31117;
  width: 85%;
  border-radius: 6px;
  padding: 8px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border: none;
  text-align: center;
  font-family: "SukhumvitSet-SemiBold";
  color: #ffffff;
  font-size: 18px;
  margin-top: 40px;
}

.login-shadow {
  width: 700px;
  height: auto;
  margin-top: 30px;
}

.error-input {
  font-family: "SukhumvitSet-Medium";
  font-size: 14px;
  margin-top: 5px;
  color: #b31117;
  margin-bottom: 0;
}

.login-input-container.error-input {
  border: 1px solid #b31117;
}

.input-label {
  font-family: "SukhumvitSet-SemiBold";
  font-size: 16px;
}

.form-title {
  font-size: 28px;
  font-family: "SukhumvitSet-SemiBold";
}

.modal-title {
  text-align: center;
  font-family: "SukhumvitSet-SemiBold";
}

.modal-des {
  font-family: "SukhumvitSet-Medium";
}

.modal-btn {
  background-color: #b31117;
  border: none;
  font-family: "SukhumvitSet-Medium";
  color: #ffffff;
  padding: 5px 15px;
  border-radius: 6px;
}

.form-container {
  width: 800px;
  border-radius: 20px;
  border: 4px solid #ffffff;
  background:linear-gradient(to top, rgba(204,204,204, 0.1) 0%, #ffffff 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* ------------------- Nav ------------------- */
.nav-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  background: #ffffff;
}

.main-bg {
  height: 100%;
  padding-bottom: 100px;
  min-height: 100vh;
}

.nav-logo {
  width: 100%;
  cursor: pointer;
}

.nav-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 25px;
  position: relative;
  cursor: pointer;
}

.nav-btn:hover, .nav-btn:hover > .nav-bottom-border {
  background:linear-gradient(to right, #cccccc 0%, #ffffff 100%);
}

.nav-icon {
  height: 25px;
  padding-right: 8px;
}

.nav-text {
  font-family: "SukhumvitSet-SemiBold";
  font-size: 18px;
  color: #000000;
  margin-bottom: 0;
}

.nav-active > .nav-text {
  color: #b31117;
}

.nav-btn:hover > .nav-text {
  color: #b31117;
}

.nav-btn:hover > .nav-icon {
  filter: grayscale(0) !important;
}

.chevron {
  position: absolute;
  right: 10px;
}

.chevron::after {
  position: relative;
  top: -2pt;
  content: "";
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  border-right: 0.15em solid black;
  border-top: 0.15em solid black;
  transform: rotate(45deg);
  margin-right: 0.5em;
}

.nav-active > .chevron::after ,.nav-btn:hover > .chevron::after {
  border-right: 0.15em solid #b31117;
  border-top: 0.15em solid #b31117;
}

.nav-btn-container {
  margin-top: 35px;
}

.nav-bottom-border {
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 15px;
  width: 170px;
  background: #cccccc;
}

.nav-active, .nav-active > .nav-bottom-border {
  background:linear-gradient(to right, #cccccc 0%, #ffffff 100%);
}

.nav-sub-container {
  padding: 0 15px;
  font-family: "SukhumvitSet-Medium";
  font-size: 16px;
  color: #000000;
  max-height: 200px;
  overflow-y: auto;
  transition: max-height 0.5s ease-out;
  background:linear-gradient(to right, #cccccc 0%, #ffffff 100%);
}

.nav-sub-container > div {
  font-family: "SukhumvitSet-SemiBold";
}

.nav-sub-container > div:hover {
  color: #b31117;
}

.nav-sub-container::-webkit-scrollbar {
  width: 0;
}

.nav-sub-container div {
  padding: 10px 0 10px 15px;
  border-bottom: 1px solid #cccccc;
  cursor: pointer;
  margin-left: 35px;
  font-size: 14px;
}

/* ------------------- Header ------------------- */

.header {
  background: #ffffff;
  width: calc(100% - 200px);
  margin-left: 200px;
  height: 70px;
  box-shadow: 0px 7px 5px 0px rgba(204,204,204,0.75);
  -webkit-box-shadow: 0px 7px 5px 0px rgba(204,204,204,0.75);
  -moz-box-shadow:0px 7px 5px 0px rgba(204,204,204,0.75);
}

.header-row {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-right: 0 !important;
}

.header-title {
  font-family: "SukhumvitSet-SemiBold";
}

.header-name {
  font-family: "SukhumvitSet-Medium";
  font-size: 16px;
}

.user-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.user-image {
  margin-bottom: 10px;
  margin-left: 20px;
  border-radius: 50%;
  cursor: pointer;
}

.user-dropdown {
  border-radius: 6px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow:0px 0px 5px 0px rgba(0,0,0,0.25);
  padding: 0 17.5px;
  position: absolute;
  background-color: #ffffff;
  width: 250px;
  top: 50px;
  transition:visibility 0.3s linear, opacity 0.3s linear;
  z-index: 1;
}

.user-profile-btn {
  height: 45px;
  font-family: "SukhumvitSet-Medium";
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-profile-btn:not(:last-child) {
  border-bottom: 1px solid rgba(0,0,0,0.25);
}

.user-profile-btn:hover {
  color: #b31117;
}

.logout-icon {
  filter: grayscale(1);
}

.user-profile-btn:hover > .logout-icon {
  filter: grayscale(0);
}

/* ------------------- Dashboard ------------------- */

.body-container {
  width: calc(100% - 250px);
  margin-left: 200px;
}

.dashboard-header {
  font-family: "SukhumvitSet-SemiBold";
  color: #b31117;
  padding-top: 25px;
  padding-bottom: 15px;
}

.map-container {
  height: 400px;
}

.marker-container {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
}

.marker-title-container {
  width: max-content;
  background-color: rgba(255,255,255,0.65);
  font-family: "SukhumvitSet-SemiBold";
  color: #000000;
  padding: 5px 15px;
  font-size: 14px;
  margin-bottom: 5px;
  position: relative;
  border-radius: 4px;
}

.marker-title-container::before {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: -15px;
  left: calc(50% - 8px);
  border: solid 8px transparent;
  border-top-color: rgba(255,255,255,0.65);
}

.dashboard-right-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.dashboard-card {
  width: 100%;
  height: 100%;
}

.dashboard-card-header {
  background-color: #b31117;
  width: 100%;
  height: 40px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: 0.5px solid #b31117;
}

.card-header-chevron::after {
  position: relative;
  top: -2pt;
  content: "";
  display: inline-block;
  width: 0.75em;
  height: 0.75em;
  border-right: 0.15em solid #ffffff;
  border-top: 0.15em solid #ffffff;
  transform: rotate(135deg);
  margin-right: 1em;
  cursor: pointer;
}

.dashboard-card-body {
  height: calc(100% - 40px);
  width: 100%;
  background-color: #ffffff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border: 0.5px solid rgba(135,135,135, 0.35);
}

.dashboard-card-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "SukhumvitSet-Medium";
  font-size: 16px;
  color: #ffffff;
}

.card-dropdown {
  border-radius: 6px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow:0px 0px 5px 0px rgba(0,0,0,0.25);
  padding: 0 17.5px;
  position: absolute;
  background-color: #ffffff;
  width: fit-content;
  top: 35px;
  right: 10px;
  transition:visibility 0.3s linear, opacity 0.3s linear;
  display: flex;
  flex-direction: column;
}

.card-dropdown-btn {
  height: 45px;
  font-family: "SukhumvitSet-Medium";
  font-size: 16px;
  align-items: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: center;
  text-align: center;
}

.card-dropdown-btn:not(:last-child) {
  border-bottom: 1px solid rgba(0,0,0,0.25);
}

.card-dropdown-btn:hover {
  color: #b31117;
}

.logout-icon {
  filter: grayscale(1);
}

.graph-container {
  width: auto;
  height: 500px;
  padding: 40px;
}

.dashboard-progress-card {
  height: fit-content;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "SukhumvitSet-SemiBold";
  color: rgb(0, 0, 0);
  font-size: 1.15vw;
  border-radius: 6px;
  padding: 10px 0;
  border: 0.5px solid rgba(135,135,135, 0.35);
}

.dashboard-progress-container {
  width: 80%;
  padding: 10px 0;
}

.dashboard-value-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dashboard-value {
  font-family: "SukhumvitSet-Thin";
  font-size: 3.5vw;
}

.dashboard-unit {
  position: absolute;
  bottom: -20%;
  font-family: "SukhumvitSet-Thin";
  font-size: 1vw;
}

.dashboard-product-container {
  background-color: #ffffff;
  border-radius: 6px;
  border: 0.5px solid rgba(135,135,135, 0.35);
  height: 180px;
}

.dashboard-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-product-name-container {
  border-left: 5px solid #b31117;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.dashboard-name-label {
  background-color: #b31117;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 1.25vw;
  font-family: "SukhumvitSet-SemiBold";
  color: #ffffff;
  text-transform: uppercase;
}

.dashboard-model {
  font-family: "SukhumvitSet-Bold";
  font-size: 20px;
}

.dashboard-serial {
  font-family: "SukhumvitSet-SemiBold";
  font-size: 16px;
  margin-top: -5px;
}

.dashboard-other-container {
  border-left: 2px solid rgba(135,135,135, 0.35);
  display: flex;
  flex-direction: column;
  position: relative;
}

.dashboard-other-label-container {
  display: flex;
  align-items: center;
  position: absolute;
  top: 10%;
}

.dashboard-other-label {
  font-family: "SukhumvitSet-SemiBold";
  font-size: 14px;
}

.dashboard-product-status {
  background-color: #3fbfef;
  height: 0.55vw;
  width: 0.55vw;
  border-radius: 50%;
  margin-right: 7.5px;
}

.dashboard-status-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "SukhumvitSet-SemiBold";
  font-size: 2.5vw;
  color: #3fbfef;
}

.dashboard-other-icon {
  margin-right: 7.5px;
}

.dashboard-switch-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "SukhumvitSet-SemiBold";
  font-size: 18px;
}

.dashboard-other-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 100%;
}

.dashboard-control-btn, .dashboard-notify-btn {
  width: 100px;
  border-radius: 6px;
  padding: 7.5px 0;
  border: none;
  background-color: #b31117;
  color: #ffffff;
  font-family: "SukhumvitSet-SemiBold";
}

.dashboard-notify-btn {
  background-color: #fbb03b;
}

.dashboard-notify-btn:disabled {
  background-color: #bdc1c6;
  color: #4d4d4d;
}

.dashboard-product-status.service-status {
  background-color: #fbb03b;
}

.dashboard-status-container.service-status {
  color: #fbb03b;
}

/* ------------------- Control ------------------- */
.control-container {
  height: 350px;
}

.control-left-colum, .control-center-colum, .control-right-colum {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.control-room-temp-container {
  height: calc(50% - 0.5rem);
  border-radius: 6px;
}

.control-room-temp-container.hot {
  background-color: #b31117;
  border: 1px solid #b31117;
}

.control-room-temp-container.cool {
  background-color: #3fbfef;
  border: 1px solid #3fbfef;
}

.control-room-control-container {
  background-color: #ffffff;
  height: 100%;
  border-radius: 6px;
  border: 0.5px solid rgba(135,135,135, 0.35);
}

.monitor-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.room-temp-label {
  font-size: 20px;
  color: #ffffff;
  font-family: "SukhumvitSet-SemiBold";
  margin-left: 10px;
  padding-top: 5px;
}

.monitor-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.monitor-value {
  display: flex;
  justify-content: center;
  align-items: center;
}

.monitor-value > p {
  font-size: 32px;
  color: #ffffff;
  font-family: "SukhumvitSet-Light";
}

.monitor-value > p > span {
  font-size: 16px;
}

.control-container-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.temp-panel {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  font-family: "SukhumvitSet-Medium";
}

.temp-panel > span {
  font-size: 22px;
  margin-top: -10px;
}

.arrow-up {
  width: 0; 
  height: 0; 
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid #b31117;
  cursor: pointer;
}

.arrow-down {
  width: 0; 
  height: 0; 
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid #b31117;
  cursor: pointer;
}

.control-label {
  font-size: 20px;
  color: #000000;
  font-family: "SukhumvitSet-SemiBold";
  margin-left: 10px;
  padding-top: 5px;
}

.control-label.disabled {
  opacity: 0.25;
}

.control-mode-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.mode-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 10px;
  font-family: "SukhumvitSet-SemiBold";
  background: transparent;
  border: none;
  opacity: 0.5;
  filter: grayscale(1);
  cursor: pointer;
}

.mode-container.active {
  opacity:1;
  filter: grayscale(0);
}

.mode-container:disabled {
  opacity: 0.25 !important;
  filter: grayscale(1) !important;
}

.mode-container > img {
  min-height: 35px;
  width: auto;
}

.control-right-border {
  border-right: 1px solid rgba(0,0,0,0.25);
  position: absolute;
  top: -40%;
  bottom: 10%;
  right: -0.125rem;
}

.control-vertical-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: calc(100% - 60px);
}

.power-status {
  font-size: 18px;
}

.plan-view-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}

.plan-view-btn {
  border-radius: 10px;
  border: 0.5px solid rgba(135,135,135, 0.35);
  height: 100%;
  width: 100%;
  background: #ffffff;
  padding: 15px;
}

.plan-view-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
  font-family: "SukhumvitSet-SemiBold";
  color: #999999;
  cursor: pointer;
}

.plan-view-btn > img {
  filter: grayscale(1);
  opacity: 0.5;
}

.plan-view-btn-container.active {
  color: #b31117;
}

.plan-view-btn-container.active > .plan-view-btn > img {
  filter: grayscale(0);
  opacity: 1;
}

.control-plan-container {
  border: 1px solid rgba(135,135,135, 0.35);
  border-radius: 6px;
  background: #ffffff;
  height: 65vh;
  width: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.control-plan {
  width: fit-content;
  position: relative;
}

.room-plan {
  width: auto;
  height: 100%;
  max-height: 50vh;
}

.ac-container, .ac-info-top, .ac-info-bottom {
  position: absolute;
  background: rgba(255,255,255,0.3);
  border-radius: 6px;
}

.ac-container > img {
  cursor: pointer;
  /* padding: 8px; */
  height: 60px;
  width: auto;
  border-radius: 6px;
}

.ac-info-top {
  width: max-content;
  top: -125px;
  left: 0;
  padding: 10px 30px 12px 15px;
}

.ac-info-bottom {
  width: max-content;
  bottom: -125px;
  left: 0;
  padding: 10px 30px 12px 15px;
}

.ac-name {
  font-family: "SukhumvitSet-SemiBold";
  font-size: 20px;
}

.ac-title {
  font-family: "SukhumvitSet-SemiBold";
  font-size: 16px;
  margin-bottom: -2.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
}

.ac-status {
  font-family: "SukhumvitSet-Medium";
  color: #3fbfef;
}

.ac-status.power-off {
  color: #999999;
}

.control-select-dropdown {
  background-color: #b31117;
  position: absolute;
  padding: 0 8px;
  top: 28px;
  right: -20px;
  border-radius: 3px;
  cursor: pointer;
}

.control-select-dropdown::after {
  position: relative;
  top: -2pt;
  content: "";
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  border-right: 0.15em solid #ffffff;
  border-top: 0.15em solid #ffffff;
  transform: rotate(135deg);
}

.control-select-container {
  background-color: #ffffff;
  position: absolute;
  border-radius: 6px;
  box-shadow: 0 0 5px 0px rgba(204,204,204,0.75);
  -webkit-box-shadow: 0 0 5px 0px rgba(204,204,204,0.75);
  -moz-box-shadow: 0 0 0 0px rgba(204,204,204,0.75);
  z-index: 9999;
  top: 60px;
  right: -22px;
  transition:visibility 0.3s linear, opacity 0.3s linear;
}

.control-select {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  font-family: "SukhumvitSet-SemiBold";
  font-size: 14px;
  width: 300px;
}

.control-select > img {
  height: 24px;
}

.control-select > p {
  width: 110px;
}

.control-search-btn {
  float: right;
  border-radius: 3px;
  border: none;
  color: #ffffff;
  background-color: #b31117;
  font-family: "SukhumvitSet-SemiBold";
  font-size: 14px;
  padding: 5px 10px;
}

/* ------------------- Product ------------------- */
.product-container {
  background-color: #ffffff;
  border-radius: 6px;
  border: 0.5px solid rgba(135,135,135, 0.35);
  height: 150px;
}

.product-date-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.product-date-label {
  font-family: "SukhumvitSet-Bold";
  font-size: 16px;
}
.product-date { 
  font-family: "SukhumvitSet-Medium";
  font-size: 16px;
}

.warranty-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.warranty-add-btn {
  background: #e6e6e6;
  height: 70px;
  width: 70px;
  border-radius: 8px;
  font-family: "SukhumvitSet-SemiBold";
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 62px;
  cursor: pointer;
}

/* ------------------- Error ------------------- */
.table-container {
  background-color: #ffffff;
  height: 100%;
  min-height: 80vh;
  border-radius: 6px;
  border: 0.5px solid rgba(135,135,135, 0.35);
  position: relative;
}

.table-container-title {
  display: flex;
  align-items: center;
}

.table-container-title h5 {
  font-family: "SukhumvitSet-Bold";
  font-size: 26px;
}

.error-table > tbody {
  border-top: none !important
}

.error-table th {
  background: #b31117 !important;
  height: 35px;
  border-bottom: 0px;
}

.error-table td {
  height: 35px;
  border-bottom: 0px;
}

.error-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.error-table tr:nth-child(odd) {
  background-color: #e6e6e6;
}

.pagination {
  flex-wrap: wrap;
}

.pagination-container {
  height: fit-content;
}

.page-link {
  color: #b31117 !important;
  cursor: pointer;
}

.page-link:hover {
  color: #ffffff !important;
  background: #b31117 !important;
}

.page-item.active .page-link {
  color: #ffffff !important;
  background-color: #b31117 !important;
  border-color:#b31117 !important;
}
/* ------------------- Report ------------------- */
.report-time {
  font-family: "SukhumvitSet-Medium";
  font-size: 16px;
}

.export-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background-color: #b31117;
  color: #ffffff;
  display: flex;
  justify-items: center;
  align-items: center;
  font-family: "SukhumvitSet-SemiBold";
  font-size: 12px;
  padding: 10px;
  border-radius: 6px;
}

/* ------------------- Profile ------------------- */
.body-container > .profile-container {
  min-height: calc(100vh - 100px);
  align-items: center;
  display: flex;
}

.profile-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.profile-form-container {
  width: 450px;
  border-radius: 20px;
  border: 4px solid #ffffff;
  background: linear-gradient(to top, rgba(204,204,204, 0.1) 0%, #ffffff 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 4px solid #ffffff
}

.profile-image-container {
  height: 120px;
  width: 120px;
  position: relative;
 
}

.profile-image-input {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 4px solid #ffffff;
  cursor: pointer;
}

/* ------------------- Setting ------------------- */
.setting-title {
  font-size: 22px;
  font-family: "SukhumvitSet-SemiBold";
}

.room-img-front {
  background: transparent;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}

.handle {
  cursor: pointer !important;
}

.drag-ac {
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  top: 0;
  cursor: pointer;
  z-index: 1;
}

.delete-ac {
  background: #b31117;
  color: #ffffff;
  font-family: "SukhumvitSet-SemiBold";
  font-size: 12px;
  border-radius: 50%;
  position: absolute;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -5px;
  right: -5px;
  cursor: pointer;
  z-index: 2;
}

.ac-list-card-container {
  height: 80vh;
  overflow-y: auto;
}

.ac-list-card-container::-webkit-scrollbar {
  width: 0px;
}

.ac-list-card {
  height: 100px;
  width: 100%;
  max-width: 250px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid rgba(135,135,135, 0.35);
  margin-bottom: 0.5rem;
  padding: 10px 15px;
}

.ac-list-card:last-child {
  margin-bottom: 0px;
}

.ac-description {
  font-family: "SukhumvitSet-Medium";
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-ac-btn-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.add-ac-btn {
  background: #E6E6E6;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  color: #b31117;
  font-family: "SukhumvitSet-Medium";
  font-size: 18px;
}

.setting-ac-info-top, .setting-ac-info-bottom {
  position: absolute;
  background: rgba(255,255,255,0.3);
  border-radius: 6px;
}

.setting-ac-info-top {
  width: max-content;
  top: -105px;
  left: 0;
  padding: 10px 30px 12px 15px;
}

.setting-ac-info-bottom {
  width: max-content;
  bottom: -105px;
  left: 0;
  padding: 10px 30px 12px 15px;
}

.setting-table > tbody {
  border-top: none !important
}

.setting-table th {
  background: #b31117 !important;
  height: 35px;
  border-bottom: 0px;
  color: #ffffff;
  font-family: "SukhumvitSet-Medium";
  font-size: 14px;
}

.setting-table td {
  height: 35px;
  border-bottom: 0px;
  font-family: "SukhumvitSet-Medium";
}

.setting-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.setting-table tr:nth-child(odd) {
  background-color: #e6e6e6;
}

.table-image {
  height: 100px;
  cursor: pointer;
}

.table-edit, .table-delete, .table-manage {
  cursor: pointer;
}

.table-delete {
  color: #b31117;
}

.table-manage {
  color: #fbb03b;
}

.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.form-image-container {
  height: 180px;
  width: 100%;
  position: relative;
  border: 1px solid #ced4da;
}

.form-image-input {
  position: absolute;
  height: 100%;
  width: auto;
  max-height: 180px;
  cursor: pointer;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.add-modal-btn, .save-position-btn {
  background-color: #b31117;
  color: #ffffff;
  display: flex;
  justify-items: center;
  align-items: center;
  font-family: "SukhumvitSet-SemiBold";
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 6px;
  border: none;
}

.save-position-btn {
  padding: 10px 15px;
}

.error-image-upload {
  border: 1px solid #b31117;
}

.form-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
  color: #dc3545;
}

.form-control {
  font-family: "SukhumvitSet-Medium";
}

.modal-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.modal-btn-container > button {
  width: 100px;
  border-radius: 6px;
}

.btn:focus, .btn:active:focus, .btn.active:focus {
  outline:none !important; 
  box-shadow:none !important;
}

.form-control:focus, .form-select:focus {
  outline:none !important; 
  box-shadow:none !important;
  border: 1px solid #ced4da !important;
}

.modal-btn-container > button:first-child {
  background: #ffffff;
  border: 1px solid #b31117;
  color: #b31117;
  font-family: "SukhumvitSet-Medium";
}

.modal-btn-container > button:last-child {
  background: #b31117;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-family: "SukhumvitSet-Medium";
}

.search-btn {
  height: 38px;
  background: #b31117;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  font-family: "SukhumvitSet-Medium";
  padding: 0 15px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}